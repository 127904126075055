import ReactDOM from 'react-dom';
import * as React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';

const queryClient = new QueryClient();

/** @typedef {{ label: string; value: string; }[]} SelectedRegions */

export class RegionsTree extends HTMLElement {
  static observedAttributes = ['did', 'campaign-id'];

  constructor() {
    super();

    /** @type {SelectedRegions} */
    this.selectedRegions = [];
  }

  connectedCallback() {
    window.addEventListener('regionschange', (e) => {
      this.selectedRegions = e.detail.selectedRegions;
    });
  }

  render() {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <RegionsSelect
          did={this.getAttribute('did')}
          campaignId={this.getAttribute('campaign-id')}
        />
      </QueryClientProvider>,
      this
    );
  }

  attributeChangedCallback(name) {
    if (name === 'did' || name === 'campaign-id') {
      this.render();
    }
  }
}

function RegionsSelect({ did, campaignId }) {
  const { data } = useQuery({
    queryKey: ['regions', did, campaignId],
    queryFn: async () => {
      const res = await fetch(`/regions/regions_tree?did=${did}&campaign_id=${campaignId}`, {
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      });
      /** @type {{selected_regions: SelectedRegions; tree: any[];}} */
      const data = await res.json();
      return data;
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(did),
  });

  React.useEffect(() => {
    if (data === undefined) {
      return;
    } else {
      window.dispatchEvent(
        new CustomEvent('regionschange', { detail: { selectedRegions: data.selected_regions } })
      );
    }
  }, [data]);

  if (!data || !did) {
    return null;
  }

  return (
    <DropdownTreeSelect
      data={data.tree}
      texts={{ placeholder: 'Select routing regions' }}
      onChange={(_currNode, selectedNodes) => {
        window.dispatchEvent(
          new CustomEvent('regionschange', { detail: { selectedRegions: selectedNodes } })
        );
      }}
      showDropdown='always'
      showPartiallySelected
      keepTreeOnSearch
      keepChildrenOnSearch
    />
  );
}

customElements.define('regions-tree', RegionsTree);
